
/*=============================================
=            footer            =
=============================================*/
.footer-content-wrapper{

    
    @media #{$large-mobile}{
        margin-bottom: 0;
    }
}

.footer-logo{
    margin-bottom: 35px;

    &--style2{
        margin-bottom: 170px;
        @media #{$desktop-device, $tablet-device, $large-mobile}{
            margin-bottom: 50px;
        }
    }
}

.footer-desc{
    color: #c7c7c7;
    max-width: 370px;
    font-family: $poppins;
    font-size: 14px;
    line-height: 2.2;
}

.footer-intro-wrapper{
    @media #{$tablet-device}{
        margin-bottom: 40px;
    }
    @media #{$large-mobile}{
        margin-bottom: 30px;
    }
}

.footer-widget-wrapper{
    @media #{$large-mobile}{
        margin-bottom: -30px;
    }
}

.footer-widget{
    @media #{$large-mobile}{
        margin-bottom: 30px;
    }

    &__title{
        color: #fff;
        margin-bottom: 50px;
        font-size: 18px;
        @media #{$tablet-device, $large-mobile}{
            margin-bottom: 20px;
        }
        &--black{
            color: $theme-color--black;
        }
    }

    &__navigation{
        line-height: 1;
        li{
            padding:  0;
            line-height: 1;
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                padding-bottom: 0;
            }
            a{
                font-size: 14px;
                color: #c7c7c7;
                transition: $transition--cubic;
                line-height: 2;
                font-family: $poppins;
                font-weight: 300;
                &:hover{
                    color: $theme-color--default;
                }
            }
        }

  
    }

    &__content {
        color: #c7c7c7;
        font-size: 14px;
        font-family: $poppins;
        font-weight: 300;
        line-height: 1.7;
      
        .contact-details {
          li {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
      
            i {
              color: #7dc345; // Adjust the icon color as needed
              margin-right: 10px;
              font-size: 16px;
            }
          }
        }
      }
      
}

.footer-copyright-wrapper{
    padding: 15px 0;
    color: #c7c7c7;
    border-top: 1px solid #4a4a4a;
    font-size: 12px;
}




/*=====  End of footer ======*/